import { useState, useEffect } from "react";
import { useParams } from "react-router";

import { toast } from "react-toastify";

import { EvaluationEmployee } from "../domain/entities/EvaluationEmployee";
import { QuestionsAllcategory } from "../domain/entities/Question";

import LoadingMolecule from "../molecules/Loading";
import { evaluationServices } from "../services/evaluationServices";
import FeedbackRoundResultsScreen, {
  EmployeeLoadingData,
} from "../templates/FeedbackRoundResultsScreen/FeedbackRoundResultsScreen";

interface FeedbackRoundData {
  feedbackRoundName: string;
  questions: QuestionsAllcategory;
  employees: EvaluationEmployee[];
  isLoadingData: boolean;
  shareFeedback: boolean;
}

export default function FeedbackRoundResultsPage() {
  const routeParams = useParams();
  const feedbackRoundId = routeParams.feedbackRoundId ?? "";

  const [feedbackRoundData, setFeedbackRoundData] = useState<FeedbackRoundData>(
    {
      feedbackRoundName: "Feedback Round",
      shareFeedback: false,
      questions: {
        peerQuestions: [],
        selfQuestions: [],
      },
      employees: [],
      isLoadingData: true,
    },
  );

  const [loadedEmployee, setLoadedEmployee] = useState<EmployeeLoadingData>({
    isLoadingAnswers: false,
    receivedAnswers: {
      peerAnswers: [],
      selfAnswers: [],
    },
    evaluators: {
      giverEvaluators: [],
      receiverEvaluators: [],
    },
    givenAnswers: {
      peerAnswers: [],
      selfAnswers: [],
    },
  });

  const fetchFeedbackRoundData = async (feedbackRoundId: string) => {
    const feedbackRound = await evaluationServices.getFeedbackRoundById(
      feedbackRoundId,
    );
    const feedbackQuestions = await evaluationServices.getEvaluationQuestions(
      feedbackRoundId.replaceAll("-", ""),
    );
    const feedbackEvaluatorEmployees =
      await evaluationServices.getEvaluatorsFromFeedbackRound(feedbackRoundId);
    return { feedbackRound, feedbackQuestions, feedbackEvaluatorEmployees };
  };

  useEffect(() => {
    fetchFeedbackRoundData(feedbackRoundId)
      .then(
        ({ feedbackRound, feedbackQuestions, feedbackEvaluatorEmployees }) => {
          setFeedbackRoundData((previousData) => {
            return {
              ...previousData,
              feedbackRoundName: feedbackRound.name,
              questions: feedbackQuestions,
              employees: feedbackEvaluatorEmployees,
              isLoadingData: false,
              shareFeedback: feedbackRound.shareFeedback,
            };
          });
        },
      )
      .catch(() => {
        setFeedbackRoundData((previousData) => {
          return {
            ...previousData,
            isLoadingData: false,
          };
        });
      });
  }, []);

  const fetchSelectedEmployeeEvaluationData = async (
    feedbackRoundId: string,
    selectedEmployeeId: string,
  ) => {
    const evaluationReviewStatus =
      await evaluationServices.getEvaluationReviewStatus(
        feedbackRoundId,
        selectedEmployeeId,
      );
    const evaluators = await evaluationServices.getEvaluatorsOfSelectedEmployee(
      feedbackRoundId,
      selectedEmployeeId,
    );
    const receivedAnswers = await evaluationServices.getReceivedAnswers(
      feedbackRoundId.replaceAll("-", ""),
      selectedEmployeeId,
    );
    const givenAnswers = await evaluationServices.getEvaluationsAnswers(
      selectedEmployeeId,
      feedbackRoundId.replaceAll("-", ""),
    );
    return {
      evaluationReviewStatus,
      evaluators,
      receivedAnswers,
      givenAnswers,
    };
  };

  useEffect(() => {
    if (
      loadedEmployee.selectedEmployee !== undefined &&
      loadedEmployee.isLoadingAnswers
    ) {
      fetchSelectedEmployeeEvaluationData(
        feedbackRoundId,
        loadedEmployee.selectedEmployee.externalId,
      )
        .then(
          ({
            evaluationReviewStatus,
            evaluators,
            receivedAnswers,
            givenAnswers,
          }) => {
            setLoadedEmployee((previousData) => ({
              ...previousData,
              evaluators,
              receivedAnswers,
              givenAnswers,
              isLoadingAnswers: false,
              wasReviewed: evaluationReviewStatus,
            }));
          },
        )
        .catch((error) => {
          console.error(error);
        });
    }
  }, [loadedEmployee]);

  const handleUpdateEvaluationReview = (isReviewed: boolean) => {
    const reviewStateUpdater = async () => {
      return await evaluationServices.updateEvaluationReviewStatus(
        feedbackRoundId,
        String(loadedEmployee.selectedEmployee?.externalId),
        isReviewed,
      );
    };
    reviewStateUpdater()
      .then((evaluation) => {
        setLoadedEmployee((previousData) => {
          return {
            ...previousData,
            wasReviewed: evaluation.wasReviewed ?? isReviewed,
          };
        });
        toast.success("Review status updated successfully");
      })
      .catch(() => {
        setLoadedEmployee((previousData) => {
          return {
            ...previousData,
            wasReviewed: !isReviewed,
          };
        });
      });
  };

  return (
    <>
      <FeedbackRoundResultsScreen
        feedbackRoundName={feedbackRoundData.feedbackRoundName}
        employees={feedbackRoundData.employees}
        questions={feedbackRoundData.questions}
        setEmployeeToLoad={setLoadedEmployee}
        employeeToLoad={loadedEmployee}
        onReviewed={handleUpdateEvaluationReview}
        feedbackRoundShareFeedback={feedbackRoundData.shareFeedback}
      />
      <LoadingMolecule condition={feedbackRoundData.isLoadingData} />
    </>
  );
}
