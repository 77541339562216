import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { unionBy } from "lodash";
import { GetEmployees } from "../../../application/use_cases/getEmployees";
import { Employee } from "../../../domain/entities/Employee";
import { onNotify } from "../../../presentation/helpers/notification-utils";
import { updateStatusEmployee } from "../../helpers";
import { EmployeeAxiosRepository } from "../../implementation/employeeAxiosRepository";
import { dataToCamelCase } from "../../implementation/helpers";

interface EmployeeState {
  employees: Employee[];
  status: string;
}

interface ActivateDeactiveEmployee {
  employeeId: string;
  scenarioId: number;
  status: boolean;
}

const initialState: EmployeeState = {
  employees: [],
  status: "",
};

const message = { success: "", error: "" };

export const getEmployees = createAsyncThunk(
  "employees/allEmployees",
  async (scenarioId: number) => {
    const employeeRepository = new EmployeeAxiosRepository();
    const getEmployeesUseCase = new GetEmployees(employeeRepository);
    const employeeList = await getEmployeesUseCase.run(scenarioId);
    return employeeList;
  },
);

export const activeDeactiveEmployee = createAsyncThunk(
  "employees/activeDeactiveEmployees",
  async ({ employeeId, scenarioId, status }: ActivateDeactiveEmployee) => {
    try {
      const result = await updateStatusEmployee(employeeId, scenarioId, status);
      const updatedEmployee = dataToCamelCase(result as any);
      return updatedEmployee;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);

export const employeeSlice = createSlice({
  name: "employeeSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEmployees.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.employees = action.payload;
      })
      .addCase(getEmployees.rejected, (state, action) => {
        state.status = "failed";
      });
    builder
      .addCase(activeDeactiveEmployee.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(activeDeactiveEmployee.fulfilled, (state, action) => {
        if (action.payload !== null) {
          // to do create a type interface for this code.
          const employees = unionBy<any>(
            [action.payload],
            current(state.employees),
            "id",
          ) as unknown as Employee[];
          state.employees = employees;
          message.success = "Employee's status successfully updated";
          onNotify(message);
          state.status = "succeeded";
        } else {
          message.error = "Error updating employee status";
          onNotify(message);
        }
      })
      .addCase(activeDeactiveEmployee.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default employeeSlice.reducer;
