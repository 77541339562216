import { GridRowsProp, GridColDef, GridComparatorFn } from "@mui/x-data-grid";
import {
  Component,
  ComponentsScenarioEmployee,
} from "../../../domain/entities/Component";
import { Employee } from "../../../domain/entities/Employee";
import { BonusCalculationApi } from "../../../domain/entities/scenario";
import {
  getEmployeeComponent,
  getComponentScore,
} from "../../helpers/components-utils";

interface BonusRowFieldData {
  id: string;
  employee: string;
  performanceBonus: string;
  generalBonus: string;
  totalBonus: string;
}

interface ScoreRowFieldData {
  id: number;
  employee: string;
  totalWeigthedScore: number;
  [key: string]: any;
}

const bonusStringValueComparator: GridComparatorFn<string> = (
  value1: string,
  value2: string,
): number => {
  return (
    parseFloat(value1.replace(/,/g, "")) - parseFloat(value2.replace(/,/g, ""))
  );
};

export function getGridScoreRows(
  gridColFieldNamesValues: string[],
  componentsByScenario: ComponentsScenarioEmployee[],
  employees: Employee[],
  allComponents: Component[],
): GridRowsProp {
  const gridRows: ScoreRowFieldData[] = [];
  const gridColComponentsName = gridColFieldNamesValues;
  gridColComponentsName.shift();
  gridColComponentsName.pop();
  let employeeIdIndex = 0;
  employees.forEach((employee: Employee): void => {
    employeeIdIndex += 1;
    const employeeComponents = getEmployeeComponent({
      employee,
      componentsByScenario,
      allComponents,
    });
    const employeeWeigthedScore =
      getComponentScore(employeeComponents).toFixed(2);
    const scoreStaticFields: ScoreRowFieldData = {
      id: employeeIdIndex,
      employee: employee.name,
      totalWeigthedScore: Number(employeeWeigthedScore),
    };
    const scoreCompletedFields = gridColComponentsName.reduce(
      (components, componentId) => {
        const employeeComponent: Component | undefined =
          employeeComponents.find((component) => {
            return String(component.id) === componentId;
          });
        const scoreComponentFields: ScoreRowFieldData = {
          [componentId]: employeeComponent?.score ?? 0,
          ...components,
        };
        return scoreComponentFields;
      },
      scoreStaticFields,
    );
    gridRows.push(scoreCompletedFields);
  });
  return gridRows;
}

export function getGridBonusRows(
  bonusData: BonusCalculationApi[],
): GridRowsProp {
  const gridRows: BonusRowFieldData[] = [];

  if (bonusData !== undefined) {
    bonusData.forEach((item: BonusCalculationApi): void => {
      const performanceBonus = parseFloat(item.performance_bonus.toFixed(2));
      const generalBonus = parseFloat(item.general_bonus.toFixed(2));
      const totalBonus = parseFloat(item.total.toFixed(2));
      const scoreData: BonusRowFieldData = {
        id: item.employee_id,
        employee: item.employee_name,
        performanceBonus: String(
          new Intl.NumberFormat().format(performanceBonus),
        ),
        generalBonus: String(new Intl.NumberFormat().format(generalBonus)),
        totalBonus: String(new Intl.NumberFormat().format(totalBonus)),
      };
      gridRows.push(scoreData);
    });
  }

  return gridRows;
}

export function setColumnsScoreFields(
  allComponents: Component[],
): GridColDef[] {
  const gridColFields: GridColDef[] = [];
  allComponents.forEach((component) => {
    gridColFields.push({
      field: String(component.id),
      headerName: `${component.name} ${component.weight}%`,
      width: 325,
      align: "center",
      headerAlign: "center",
    });
  });
  gridColFields.unshift({
    field: "employee",
    headerName: "Employees",
    width: 325,
    align: "center",
    headerAlign: "center",
  });
  gridColFields.push({
    field: "totalWeigthedScore",
    headerName: "Total weigthed score",
    width: 300,
    align: "center",
    headerAlign: "center",
  });
  return gridColFields;
}

export const bonusColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "External id",
    width: 260,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "employee",
    headerName: "Employees",
    width: 260,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "performanceBonus",
    headerName: "Perfomance bonus",
    width: 260,
    align: "center",
    sortComparator: bonusStringValueComparator,
    headerAlign: "center",
  },
  {
    field: "generalBonus",
    headerName: "General bonus",
    width: 260,
    align: "center",
    sortComparator: bonusStringValueComparator,
    headerAlign: "center",
  },
  {
    field: "totalBonus",
    headerName: "Total bonus",
    width: 230,
    align: "center",
    sortComparator: bonusStringValueComparator,
    headerAlign: "center",
  },
];
