import { useState } from "react";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  Snackbar,
} from "@mui/material";
import { Container } from "react-bootstrap";
import {
  Component,
  ComponentsScenarioEmployee,
} from "../../../domain/entities/Component";
import {
  scoreComponentsUpdate,
  updateComponentThunk,
} from "../../../infrastructure/redux/features/componentsSlice";
import { Employee } from "../../../domain/entities/Employee";
import { useTypedDispatch } from "../../../infrastructure/redux/store";
import { ERRORSCOREMSG, MAXSCORE, MINSCORE } from "../constants";
import { onlyWholeNumber } from "../../helpers/unput-utils";
import InputAtom from "../../../atoms/Input";

interface Props {
  employeeList: Employee[];
  componentsByScenario: ComponentsScenarioEmployee[];
  currentComponent: Component;
  scenarioId: number;
}

const ComponentDetailComponent = ({
  componentsByScenario,
  employeeList,
  currentComponent,
  scenarioId,
}: Props) => {
  const dispatch = useTypedDispatch();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function saveComponentChange(
    scenarioId: number,
    componentEmployee: ComponentsScenarioEmployee,
  ) {
    void dispatch(
      updateComponentThunk({
        scenarioId,
        componentEmployee,
      }),
    );
  }

  const saveComponentEmployeeScore = (
    componentEmployee: ComponentsScenarioEmployee,
  ) => {
    const isValid =
      componentEmployee.score >= MINSCORE &&
      componentEmployee.score <= MAXSCORE;
    if (isValid) {
      dispatch(scoreComponentsUpdate(componentEmployee));
      saveComponentChange(scenarioId, componentEmployee);

      setOpen(true);
    }
  };

  const updateComponentScoreHandler =
    (
      componentData: ComponentsScenarioEmployee,
      setShowScoreMessage: React.Dispatch<React.SetStateAction<boolean>>,
    ) =>
    (event: any) => {
      const score = +event.target.value;
      setShowScoreMessage(false);
      if (score < MINSCORE || score > MAXSCORE) setShowScoreMessage(true);
      try {
        const updatedComponentByEmployee = {
          ...componentData,
          score,
        } as unknown as ComponentsScenarioEmployee;
        void saveComponentEmployeeScore(updatedComponentByEmployee);
      } catch (exception) {
        console.log(exception);
      }
    };

  return (
    <Container>
      <Typography variant="h4">Component Data</Typography>
      <hr />
      <Stack spacing={2} direction="row">
        <Typography>
          <b>Name: </b>
        </Typography>
        <Typography>{currentComponent.name}</Typography>
      </Stack>
      <Stack spacing={2} direction="row">
        <Typography>
          <b>Description: </b>
        </Typography>
        <Typography>{currentComponent.description}</Typography>
      </Stack>
      <Stack spacing={2} direction="row">
        <Typography>
          <b>Weight: </b>
        </Typography>
        <Typography>{currentComponent.weight}</Typography>
      </Stack>
      <hr />
      <Typography>
        <b>Employee List</b>
      </Typography>
      <List>
        {employeeList.map((employee, index) => {
          const [showScoreMessage, setShowScoreMessage] =
            useState<boolean>(false);
          const componentData = componentsByScenario.find(
            (comp) =>
              comp.componentId === currentComponent.id &&
              comp.employeeId === employee.id,
          );
          return (
            <div key={`${employee.id ?? index}-${currentComponent.id}`}>
              <ListItem
                data-testid="employee-item-element"
                alignItems="flex-start"
                secondaryAction={
                  <Stack spacing={2} direction="row">
                    {showScoreMessage && (
                      <p
                        className="error-message"
                        data-testid="error-msg-score-out-range"
                      >
                        {ERRORSCOREMSG}
                      </p>
                    )}
                    <Typography data-testid="scorePText">Score</Typography>
                    <InputAtom
                      size="small"
                      id="standard-basic"
                      dataTestid="employeeItemScore"
                      style={{ width: "4em" }}
                      type="number"
                      min={MINSCORE}
                      max={MAXSCORE}
                      disabled={!employee.status}
                      defaultValue={componentData?.score}
                      onKeyPress={(event) => {
                        onlyWholeNumber(event);
                      }}
                      onBlur={updateComponentScoreHandler(
                        componentData === undefined
                          ? {
                              id: null,
                              score: 0,
                              scenarioId,
                              componentId: currentComponent.id ?? 0,
                              employeeId: employee.id ?? 0,
                              comment: "",
                              subComponents: [],
                            }
                          : componentData,
                        setShowScoreMessage,
                      )}
                    />
                  </Stack>
                }
                disablePadding
              >
                <ListItemAvatar>
                  <Avatar>{employee.name[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={employee.name}
                  secondary={
                    <>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        Status
                      </Typography>
                      {employee.status ? " - Activated" : " - Deactivated"}
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          );
        })}
      </List>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={6000}
        message="Saving..."
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        data-testid="scoreSnackbar"
      />
    </Container>
  );
};

export default ComponentDetailComponent;
