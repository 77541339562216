import { useState, useEffect } from "react";

import { Answer, Answers } from "../../domain/entities/Answer";
import { Question, QuestionsAllcategory } from "../../domain/entities/Question";
import {
  EmployeeEvaluators,
  EvaluationEmployee,
} from "../../domain/entities/EvaluationEmployee";

import ContainerAtom from "../../atoms/Container";
import UnidimensionalLayout from "../../atoms/UnidimensionalLayout";
import TitleAtom from "../../atoms/Title";
import PaperSurfaceAtom from "../../atoms/PaperSurface";

import NextAndPreviousActions from "../../molecules/NextAndPreviousActions";
import SelectionToggleAutocomplete from "../../molecules/SelectionToggleAutocomplete";
import BasicTabs from "../../molecules/BasicTabs";
import LoadingMolecule from "../../molecules/Loading";

import PeerReviewsRenderer from "../../organisms/PeerReviewsRenderer";
import ReceivedAnswers from "../../organisms/ReceivedAnswers";

import styles from "./styles.module.css";
import CheckboxAndLabel from "../../molecules/CheckboxAndLabel";
import AtomicButton from "../../atoms/Button";
import RatingAtom from "../../atoms/Rating";

export interface EmployeeLoadingData {
  selectedEmployee?: EvaluationEmployee;
  isLoadingAnswers: boolean;
  wasReviewed?: boolean;
  receivedAnswers: Answers;
  givenAnswers: Answers;
  evaluators: EmployeeEvaluators;
}
export interface FeedbackRoundResultsProps {
  feedbackRoundName: string;
  employees: EvaluationEmployee[];
  questions: QuestionsAllcategory;
  setEmployeeToLoad: React.Dispatch<React.SetStateAction<EmployeeLoadingData>>;
  employeeToLoad: EmployeeLoadingData;
  onReviewed: (isReviewed: boolean) => void;
  feedbackRoundShareFeedback: boolean;
}

export interface AccordionStates {
  [key: string]: boolean;
}

const NUMBER_OF_DECIMALS = 1;

export function getAverageScore(
  answers: Answer[],
  questions: Question[],
): number {
  const averageOfRatingQuestions = questions.map((question) => {
    const answersForQuestions = answers.filter(
      (answer) =>
        answer.questionId === question.id && answer.scoreResponse !== 0,
    );
    const convertedRatingAnswers = answersForQuestions.map((answer) => {
      return convertToMaxScore(
        parseInt(question.scoreRangeTo ?? "0"),
        answer.scoreResponse ?? 0,
      );
    });
    let sumOfConvertedRatingAnswers;
    sumOfConvertedRatingAnswers = 0;
    if (convertedRatingAnswers.length !== 0) {
      sumOfConvertedRatingAnswers = convertedRatingAnswers.reduce(
        (sum, currentValue) => sum + currentValue,
      );
    }
    return sumOfConvertedRatingAnswers / answersForQuestions.length;
  });
  const filteredAnswersOfRatingQuestions = averageOfRatingQuestions.filter(
    (averageRatingQuestion) => !isNaN(averageRatingQuestion),
  );
  const sumOfRatingAnswers = filteredAnswersOfRatingQuestions.reduce(
    (sum, currentValue) => sum + currentValue,
    0,
  );
  return +(
    sumOfRatingAnswers / filteredAnswersOfRatingQuestions.length
  ).toFixed(NUMBER_OF_DECIMALS);
}

export const convertToMaxScore = (maxScore: number, scoreResponse: number) => {
  const maxAverageScore = 5;
  return (scoreResponse * maxAverageScore) / maxScore;
};

function FeedbackRoundResultsScreen({
  feedbackRoundName,
  questions,
  setEmployeeToLoad,
  employeeToLoad,
  employees,
  onReviewed,
  feedbackRoundShareFeedback,
}: FeedbackRoundResultsProps) {
  const { selectedEmployee, isLoadingAnswers, wasReviewed } = employeeToLoad;
  const { giverEvaluators, receiverEvaluators } = employeeToLoad.evaluators;
  const [employeePositionInList, setEmployeePositionInList] =
    useState<number>(-1);
  const [isReviewed, setIsReviewed] = useState(false);
  const [valueSelect, setValue] = useState({
    label: "",
    value: "",
  });
  const [isOpenGiven, setIsOpenGiven] = useState<boolean>(false);
  const [isOpenReceived, setIsOpenReceived] = useState<boolean>(false);
  const [accordionStates, setAccordionStates] = useState<AccordionStates>({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [averageScore, setAverageScore] = useState(0);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    setIsReviewed((previousState) =>
      wasReviewed === undefined ? false : wasReviewed,
    );
  }, [wasReviewed]);

  useEffect(() => {
    if (selectedEmployee !== undefined && !isLoadingAnswers) {
      const newEmployeePosition = employees.findIndex((employee) => {
        return employee.externalId === selectedEmployee.externalId;
      });
      setEmployeePositionInList(newEmployeePosition);
      setAverageScore(
        getAverageScore(
          employeeToLoad.receivedAnswers.peerAnswers,
          questions.peerQuestions,
        ),
      );
    }
  }, [isLoadingAnswers]);

  useEffect(() => {
    setSelectedTab(0);
  }, [selectedEmployee]);

  const updateSelectedEmployee = (selectedEmployee: EvaluationEmployee) => {
    setEmployeeToLoad((previousEmployee) => {
      return {
        ...previousEmployee,
        selectedEmployee,
        isLoadingAnswers: true,
        evaluators: {
          giverEvaluators: [],
          receiverEvaluators: [],
        },
        receivedAnswers: {
          peerAnswers: [],
          selfAnswers: [],
        },
        givenAnswers: {
          peerAnswers: [],
          selfAnswers: [],
        },
      };
    });
  };
  const handleChangeEmployeeOnPosition = (position: "next" | "previous") => {
    const currentEmployeePosition = employeePositionInList;
    const newEmployeePosition =
      currentEmployeePosition + (position === "next" ? 1 : -1);
    const selectedEmployee = employees.find(
      (employee, index) => index === newEmployeePosition,
    );
    if (selectedEmployee !== undefined) {
      updateValueSelect(selectedEmployee);
      updateSelectedEmployee(selectedEmployee);
    }
  };
  const handleSelectEmployee = (externalId: string) => {
    const selectedEmployee = employees.find((employee) => {
      return employee.externalId === externalId;
    });
    if (selectedEmployee !== undefined) {
      updateValueSelect(selectedEmployee);
      updateSelectedEmployee(selectedEmployee);
    }
  };
  const showButton = false;

  const updateValueSelect = (employee: EvaluationEmployee) => {
    setValue({ label: employee?.name, value: employee?.externalId });
  };

  const searchOptions = employees.map((employee) => {
    return { label: employee.name, value: employee.externalId };
  });
  const emptyPage = (
    <p className={styles.contentPadding + " " + styles.centerText}>
      <i>Press search to show results</i>
    </p>
  );

  const resetAccordion = () => {
    const resetState: AccordionStates = {};
    for (const employeeId in accordionStates) {
      if (Object.prototype.hasOwnProperty.call(accordionStates, employeeId)) {
        resetState[`${employeeId}`] = false;
      }
    }
    setAccordionStates(resetState);
  };

  let pageContent = <></>;
  if (selectedEmployee === undefined) {
    pageContent = emptyPage;
  } else {
    const DEFAULT_TABS = [
      {
        tabLabel: "Feedback Received",
        tabChildren: (
          <>
            {employeeToLoad.receivedAnswers.peerAnswers.length > 0 && (
              <>
                <ContainerAtom
                  className={`${styles.containerButtonOpenReceived} ${styles.containerSpaceBottom}`}
                  disableGutters
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <TitleAtom variant="h6" style={{ marginRight: "10px" }}>
                      <b>Average Score:</b> {averageScore}
                    </TitleAtom>
                    <RatingAtom
                      size="large"
                      dataTestId="avgScoreStars"
                      value={averageScore}
                      max={5}
                      readOnly={true}
                      precision={0.1}
                    />
                  </div>
                  <AtomicButton
                    className="button-primary-light-blue"
                    onClick={() => setIsOpenReceived(true)}
                  >
                    Open All
                  </AtomicButton>
                </ContainerAtom>
                <PeerReviewsRenderer
                  questions={questions.peerQuestions}
                  answers={employeeToLoad.receivedAnswers.peerAnswers}
                  evaluatorEmployees={giverEvaluators}
                  selectedEmployee={selectedEmployee}
                  evaluatorsRole="giver"
                  feedbackRoundShareFeedback={feedbackRoundShareFeedback}
                  isOpen={isOpenReceived}
                  accordionStates={accordionStates}
                  setAccordionStates={setAccordionStates}
                />
                <ContainerAtom
                  className={`${styles.containerButtonOpen} ${styles.containerSpaceTop}`}
                  disableGutters
                >
                  <AtomicButton
                    className="button-primary-light-blue"
                    onClick={() => {
                      resetAccordion();
                      return setIsOpenReceived(false);
                    }}
                  >
                    Close All
                  </AtomicButton>
                </ContainerAtom>
              </>
            )}
          </>
        ),
      },
      {
        tabLabel: "Feedback Given",
        tabChildren: (
          <UnidimensionalLayout
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            style={{ width: "100%" }}
          >
            <ContainerAtom
              className={styles.containerButtonOpen}
              disableGutters
            >
              <AtomicButton
                className="button-primary-light-blue"
                onClick={() => setIsOpenGiven(true)}
              >
                Open All
              </AtomicButton>
            </ContainerAtom>
            <TitleAtom variant="h6" className={styles.subSectionTitle}>
              Self-evaluation feedback
            </TitleAtom>
            <ReceivedAnswers
              questions={questions.selfQuestions}
              receivedAnswers={employeeToLoad.givenAnswers.selfAnswers}
              isOpen={isOpenGiven}
              accordionStates={accordionStates}
              setAccordionStates={setAccordionStates}
            />
            <TitleAtom variant="h6" className={styles.subSectionTitle}>
              Peer evaluation feedback
            </TitleAtom>
            <PeerReviewsRenderer
              questions={questions.peerQuestions}
              answers={employeeToLoad.givenAnswers.peerAnswers}
              evaluatorEmployees={receiverEvaluators}
              selectedEmployee={selectedEmployee}
              evaluatorsRole="receiver"
              feedbackRoundShareFeedback={feedbackRoundShareFeedback}
              isOpen={isOpenGiven}
              accordionStates={accordionStates}
              setAccordionStates={setAccordionStates}
            />
            <ContainerAtom
              className={`${styles.containerButtonOpen} ${styles.containerSpaceTop}`}
              disableGutters
            >
              <AtomicButton
                className="button-primary-light-blue"
                onClick={() => {
                  resetAccordion();
                  return setIsOpenGiven(false);
                }}
              >
                Close All
              </AtomicButton>
            </ContainerAtom>
          </UnidimensionalLayout>
        ),
      },
    ];
    pageContent = (
      <UnidimensionalLayout
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        className={styles.contentPadding}
      >
        <UnidimensionalLayout
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ width: "100%" }}
        >
          <h2>{`Employee: ${selectedEmployee.name}`}</h2>
          <CheckboxAndLabel
            dataTestId={`reviewedCheckbox-${selectedEmployee.externalId}`}
            label="Reviewed"
            onClick={() => {
              onReviewed(!isReviewed);
            }}
            checked={isReviewed}
          />
        </UnidimensionalLayout>

        <BasicTabs
          tabInfo={DEFAULT_TABS}
          selectedTab={selectedTab}
          setSelectedTab={handleChange}
        />
      </UnidimensionalLayout>
    );
  }

  return (
    <>
      <ContainerAtom>
        <UnidimensionalLayout
          direction="column"
          justifyContent="space-evenly"
          alignItems="stretch"
          className={styles.layoutSpaces}
        >
          <TitleAtom
            variant="h4"
            style={{ fontWeight: "bold", margin: "10px 0 15px 0" }}
          >
            {feedbackRoundName}
          </TitleAtom>
          <PaperSurfaceAtom
            className={styles.paperContainer}
            square={true}
            variant="outlined"
            elevation={0}
          >
            <UnidimensionalLayout
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: "100%" }}
            >
              <SelectionToggleAutocomplete
                options={searchOptions}
                showButton={showButton}
                value={valueSelect}
                style={{ width: "50%" }}
                onAdd={handleSelectEmployee}
              />
              <NextAndPreviousActions
                onNext={() => {
                  handleChangeEmployeeOnPosition("next");
                }}
                onPrevious={() => {
                  handleChangeEmployeeOnPosition("previous");
                }}
                nextLabel="Next employee"
                previousLabel="Previous employee"
                disabled={{
                  previous: employeePositionInList <= 0,
                  next: employeePositionInList >= employees.length - 1,
                }}
              />
            </UnidimensionalLayout>
            <UnidimensionalLayout
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {pageContent}
            </UnidimensionalLayout>
          </PaperSurfaceAtom>
        </UnidimensionalLayout>
      </ContainerAtom>
      <LoadingMolecule condition={isLoadingAnswers} />
    </>
  );
}

FeedbackRoundResultsScreen.defaultProps = {
  onReviewed: () => {},
};

export default FeedbackRoundResultsScreen;
