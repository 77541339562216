import { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import {
  getComponentsByScenarioThunk,
  getComponentsScenarioEmployee,
} from "../../../infrastructure/redux/features/componentsSlice";
import ComponentDetailComponent from "./ComponentDetailComponent";
import { getEmployees } from "../../../infrastructure/redux/features/employeeSlice";
import {
  useTypedSelector,
  useTypedDispatch,
} from "../../../infrastructure/redux/store";
import LoadingComponent from "../LoadingComponent";
import ComponentListComponent from "./ComponentListComponent";

const ComponentDetailContainer = () => {
  const dispatch = useTypedDispatch();

  const components = useTypedSelector(
    (state) => state.componentReducer.components,
  );
  const componentsByScenario = useTypedSelector(
    (state) => state.componentReducer.componentsByScenario,
  );
  const loadingEmployees = useTypedSelector(
    (state) => state.employeeReducer.status,
  );
  const employeeList = useTypedSelector(
    (state) => state.employeeReducer.employees,
  );
  const loadingComponents = useTypedSelector(
    (state) => state.componentReducer.statusComponents,
  );
  const loadingComponentsByScenario = useTypedSelector(
    (state) => state.componentReducer.statusComponentsByScenario,
  );
  const scenario = useTypedSelector((state) => state.scenarioReducer.scenario);

  const [currentComponent, setCurrentComponent] = useState(components[0]);

  useEffect(() => {
    if (components.length <= 0)
      void dispatch(getComponentsByScenarioThunk(scenario?.id ?? 0));
    if (employeeList.length <= 0)
      void dispatch(getEmployees(scenario?.id ?? 0));
    if (componentsByScenario.length <= 0)
      void dispatch(getComponentsScenarioEmployee(scenario?.id ?? 0));
  }, []);

  useEffect(() => {
    setCurrentComponent(components[0]);
  }, [components]);

  if (
    loadingComponents === "loading" ||
    loadingComponentsByScenario === "loading" ||
    loadingEmployees === "loading" ||
    currentComponent === undefined
  )
    return <LoadingComponent />;

  return (
    <Container>
      <Row>
        <Col lg="4" sm="2">
          <ComponentListComponent
            currentComponent={currentComponent}
            componentList={components}
            selectComponent={setCurrentComponent}
          />
        </Col>
        <Col>
          <ComponentDetailComponent
            employeeList={employeeList}
            componentsByScenario={componentsByScenario}
            currentComponent={currentComponent}
            scenarioId={scenario?.id ?? 0}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ComponentDetailContainer;
